import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Button } from "@mui/material";

function SearchInput({ searchTerm, setSearchTerm }) {
  return (
    <div className="certificate-searchInputContainer">
      <p className="certificate-searchIcon">
        <SearchIcon sx={{ fontSize: "2.4rem" }} />
      </p>
      <input
        className="certificate-searchInput"
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <div className="certificate-searchButton">
        <Button
          size="medium"
          variant="blueBtn"
          sx={{ width: "10rem", heigt: "2rem" }}
        >
          Buscar
        </Button>
      </div>
    </div>
  );
}

export default SearchInput;
