
export const HOME = '/'
export const LOGIN_URI = '/login';
export const REGISTER_URI = '/register';
export const REGISTER_VALIDATION_URI = `${REGISTER_URI}/email-validation`;
export const CHANGE_PASSWORD = "/change-password";
export const FORGOT_PASSWORD = '/forgot-password';
export const FORGOT_PASSWORD_STEP_TWO = '/forgot-password-step-two';
export const FORGOT_PASSWORD_STEP_THREE = '/forgot-password-step-three';
export const LOGIN_MISTRAMITES_URI = '/login-mistramites';
export const ASSOCIATE_CUIT = '/associate-cuit';
export const NEW_TRANSACT = '/new-transact';
export const NEW_TRANSACT_STEP_TWO = '/new-transact-step-two';
export const NEW_TRANSACT_STEP_THREE = '/new-transact-step-three';
export const NEW_TRANSACT_STEP_FOUR = '/new-transact-step-four';
export const NEW_TRANSACT_STEP_FIVE = '/new-transact-step-five';
export const PROFILE_URI = '/profile';
export const REQUIREMENTS_URI = '/requirements';
export const MUNICIPAL_AUTHORIZATION_URI = '/municipal-authorization';
export const MEMBERS_UPLOAD_URI = '/members-upload';
export const REPRESENTATIVES_URI = '/representatives-upload';
export const ASSOCIATE_DETAILS_URI = '/associate-details';
export const REQUIREMENTS_REVIEW_URI = '/requirements-review';
export const DELETE_ACCOUNT_URI = '/delete-account';
export const DOWNLOAD_CERTIFICATE_URI = '/download-certificate';

export const ROUTE_PATHS = {
    HOME,
    LOGIN_URI,
    REGISTER_URI,
    LOGIN_MISTRAMITES_URI,
    REGISTER_VALIDATION_URI,
    CHANGE_PASSWORD,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_STEP_TWO,
    FORGOT_PASSWORD_STEP_THREE,
    ASSOCIATE_CUIT,
    NEW_TRANSACT,
    NEW_TRANSACT_STEP_TWO,
    NEW_TRANSACT_STEP_THREE,
    NEW_TRANSACT_STEP_FOUR,
    NEW_TRANSACT_STEP_FIVE,
    PROFILE_URI,
    REQUIREMENTS_URI,
    MUNICIPAL_AUTHORIZATION_URI,
    MEMBERS_UPLOAD_URI,
    REPRESENTATIVES_URI,
    ASSOCIATE_DETAILS_URI,
    REQUIREMENTS_REVIEW_URI,
    DELETE_ACCOUNT_URI,
    DOWNLOAD_CERTIFICATE_URI
};
