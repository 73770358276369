import { Grid, Fab, Typography,  Slide } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import React, { useEffect } from "react";
import MediaCard from "../mediaCard";
import { history } from "../../_helpers/history";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import Icons from "../../assets/icons/Icons";
import SimpleGrow from "../common/simpleGrow";
import { clearRequirementsState, restartRequirementState, setProcedureStatus } from "./requirementsPage/requirementsSlice";
import { clearNewTransactState } from "../../features/newTransact/newTransactSlice";
import { clearMunicipalAuthorizations, persistProcedureId, setTypeOfProcedureSelected, tryGetMunicipalAuthorizationsByProcedureId } from "./municipalAuthorizationPage/municipalAuthorizationSlice";
import { clearRepresentativesState, tryGetRepresentativeByProcedureId } from "./representativesPage/representativesSlice";
import { clearMembers, setKindOfPersonSelected } from "./membersUploadPage/membersUploadSlice";
import { tryCloseModal } from "../modals/modalSlice";
import ProvidersTable from "../providersTable";
import { fetchLastTransactsTable } from "../../features/lastTransactsTable/tableSlice";
import { ASSOCIATE_CUIT, DOWNLOAD_CERTIFICATE_URI, HOME, MUNICIPAL_AUTHORIZATION_URI, NEW_TRANSACT } from "../../routing/constants";
import { tryResendMailToValidate } from "./requirementsReviewPage/requirementsReviewSlice";


export default function HomePage(props) {
  const userAlias = useSelector((state) => state.login.authentication?.alias);
  const transactsFull = useSelector(
    (state) => state?.lastTransactsTable?.transacts?.data
  );

  const transacts = transactsFull?.map((v) => ({
    id: v?.procedureId,
    date: v?.procedureState?.date,
    cuit: v?.provider?.person?.cuit,
    name: v?.provider?.person?.name,
    type: v?.procedureType?.name,
    status: v?.procedureState?.name,
    kindOfPerson: v?.provider?.person?.kindOfPerson,
    email:
      v?.provider?.person?.emails !== null
        ? v?.provider?.person?.emails.find(
            (element) => element?.state === "Confirmado"
          )?.email
        : "",
    isPending: "",
  }));
  const dispatch = useDispatch();
  const handleViewPersons = () => {
    history.navigate(ASSOCIATE_CUIT);
    dispatch(tryCloseModal());
  };

  const handleNewTransact = () => {
    history.navigate(NEW_TRANSACT);
  };

  const handleViewCertificates = () => {
    history.navigate(DOWNLOAD_CERTIFICATE_URI);
  };

  const handleTransactHistory = () => {
    history.navigate(HOME);
  };
  
  useEffect(() => {
    dispatch(clearRequirementsState());
    dispatch(clearNewTransactState());
    dispatch(clearMunicipalAuthorizations());
    dispatch(clearRepresentativesState());
    dispatch(clearMembers());
    dispatch(fetchLastTransactsTable());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenRequirements = (procedure) => {
    if (procedure?.status === "Borrador" || procedure?.status === "Observado") {
      dispatch(clearMunicipalAuthorizations());
      dispatch(persistProcedureId(procedure?.id));
      dispatch(setTypeOfProcedureSelected(procedure?.type));
      dispatch(setKindOfPersonSelected(procedure?.kindOfPerson));
      dispatch(tryGetMunicipalAuthorizationsByProcedureId(procedure?.id));
      dispatch(tryGetRepresentativeByProcedureId(procedure?.id));
      dispatch(restartRequirementState());
      dispatch(setProcedureStatus(procedure?.status));
      history.navigate(MUNICIPAL_AUTHORIZATION_URI);
    }
  };

  const handleResendMail = (procedureId) => {
    dispatch(tryResendMailToValidate(procedureId));
  }

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      variant="custom"
      justifyContent="center"
      sx={{ mb: "3rem" }}
    >
      <Grid
        sx={{ width: "90%" }}
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Box
            sx={{
              textAlign: "left",
              fontFamily: "Open sans",
              fontSize: "1.6rem",
            }}
          ></Box>
        </Grid>
        <Slide
          direction="left"
          in={true}
          mountOnEnter
          unmountOnExit
          timeout={1000}
        >
          <Grid item>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="add"
              onClick={handleNewTransact}
              class="fab__zoom"
              sx={{ width: "19rem" }}
            >
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <AddCircleOutlineIcon
                    sx={{
                      mr: "1rem",
                      width: "2rem",
                      height: "2rem",
                    }}
                  />
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      typography: "title3",
                      fontWeight: "bold",
                      fontSize: "1.2rem",
                    }}
                  >
                    INICIAR TRÁMITE
                  </Box>
                </Grid>
              </Grid>
            </Fab>
          </Grid>
        </Slide>
      </Grid>

      <Box
        sx={{
          selfAlign: "start",
          width: "90%",
          mb: "1rem",
        }}
      >
        <Typography variant="homeTitle">Bienvenido/a, {userAlias}</Typography>
      </Box>
      <Box
        sx={{
          textAlign: "left",
          selfAlign: "start",
          width: "90%",
          mb: "3rem",
        }}
      >
        <Grid xs={12} md={8} lg={7}>
          <Typography variant="homeDescription" justifyContent="left">
            Gestione personas físicas y jurídicas para que sean Proveedores del
            Estado.
          </Typography>
        </Grid>
      </Box>
      {transacts && transacts.length > 0 && (
        <SimpleGrow timeout={2000}>
          <Grid item sx={{ width: "90%" }}>
            <ProvidersTable
              data={transacts}
              rows={5}
              clickFunction={handleOpenRequirements}
              actionFunction={handleResendMail}
            />
          </Grid>
        </SimpleGrow>
      )}
      <Grid
        container
        spacing={2}
        sx={{
          width: { xs: "100%", sm: "100%", lg: "100%" },
          margin: "5rem 0 0 0",
          padding: "0 5rem 0 5rem",
          textAlign: "-webkit-center",
          alignSelf: "start",
        }}
      >
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SimpleGrow timeout={1500}>
            <div className="div-media-card">
              <MediaCard
                title="Personas Asociadas"
                description="Administración de Personas (Humanas o jurídicas) que se pueden gestionar."
                icon={Icons.usersIcon}
                buttonLabel="VER"
                handleButton={handleViewPersons}
              />
            </div>
          </SimpleGrow>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SimpleGrow timeout={2000}>
            <div className="div-media-card">
              <MediaCard
                title="Iniciar trámite"
                description="En base a la última situación de las personas asociadas podrá empezar un trámite."
                icon={Icons.transactIcon}
                buttonLabel="INICIAR"
                handleButton={handleNewTransact}
              />
            </div>
          </SimpleGrow>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SimpleGrow timeout={2500}>
            <div className="div-media-card">
              <MediaCard
                title="Descargar constancia"
                description="Obtenga las constancias disponibles para sus personas asociadas."
                icon={Icons.certificateIcon}
                buttonLabel="DESCARGAR"
                handleButton={handleViewCertificates}
              />
            </div>
          </SimpleGrow>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          <SimpleGrow timeout={3000}>
            <div className="div-media-card">
              <MediaCard
                title="Historial de trámites"
                disabled={true}
                description="Consulta de los trámites realizados."
                icon={Icons.transactHistoryIcon}
                buttonLabel="CONSULTAR"
                handleButton={handleTransactHistory}
                styles={{ backgroundColor: "#E4EEFC" }}
              />
            </div>
          </SimpleGrow>
        </Grid>
      </Grid>
    </Grid>
  );
}
