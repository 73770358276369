import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import Icons from "../../../assets/icons/Icons";
import { maskCUIT } from "../../../utils/utils";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

function CertificateCard({ disabled = false, data = null }) {
  const handleButton = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <Card
        variant="actionCard"
        sx={{
          minHeight: "11rem",
          width: { xs: "100%", md: "80%" },
          marginBottom: "1rem",
          backgroundColor: disabled ? "#E4EEFC" : "rgba(255,255,255,1)",
          borderRadius: "1rem",
        }}
      >
        <Grid container direction="row">
          <Grid item sx={{ width: "5rem", p: "2.2rem 0rem 0rem 2rem" }}>
            <Avatar
              sx={{
                filter: disabled && "brightness(3) saturate(0.3)",
                width: "3rem",
                height: "3rem",
                p: "1rem",
                border: "0.2rem solid #1A3762",
                backgroundColor: "transparent",
                top: "0rem",
                "&:hover": { cursor: "pointer" },
                "& img": {
                  width: "1.2rem",
                  height: "1.2rem",
                },
              }}
            >
              {" "}
              <img src={Icons.userIcon} alt="User Icon" />{" "}
            </Avatar>
          </Grid>
          <Grid item xs>
            <CardContent
              sx={{
                height: "100%",
                padding: "2rem",
                textAlign: "start",
                width: "100%",
              }}
            >
              <Typography
                gutterBottom
                variant="certificateCardTitle"
                component="div"
                justifyContent="start"
                sx={{ color: disabled && "#6D83A0" }}
              >
                {data?.name}
              </Typography>
              <Typography
                variant="certificateCardDescription"
                justifyContent="start"
                sx={{ color: disabled && "#6D83A0" }}
              >
                {maskCUIT(data?.cuit)}
              </Typography>
            </CardContent>
          </Grid>

          <Grid item sx={{ width: "23rem" }}>
            <CardActions
              sx={{
                justifyContent: "end",
                alignItems: "start",
                position: "relative",
                width: "100%",
                height: "11rem",
                p: "1.4rem 2rem 0 0",
              }}
            >
              <Grid container direction="column" gap={1}>
                {data?.providerDetails?.activeReportUrls?.current && (
                  <Button
                    onClick={() =>
                      handleButton(
                        data?.providerDetails?.activeReportUrls?.current
                      )
                    }
                    size="medium"
                    variant="blueBtn"
                    sx={{ width: "20rem", heigt: "2rem" }}
                    endIcon={<FileDownloadOutlinedIcon />}
                  >
                    Constancia definitiva
                  </Button>
                )}
                {data?.providerDetails?.activeReportUrls?.pending && (
                  <Button
                    onClick={() =>
                      handleButton(
                        data?.providerDetails?.activeReportUrls?.pending
                      )
                    }
                    size="medium"
                    variant="transparentBtn"
                    sx={{ width: "20rem", height: "2rem" }}
                    endIcon={<FileDownloadOutlinedIcon />}
                  >
                    Constancia provisoria
                  </Button>
                )}
                {!data?.providerDetails?.activeReportUrls && (
                  <Button
                    size="medium"
                    variant="disabledCertificateButton"
                    sx={{ width: "20rem", height: "2rem" }}
                  >
                    Constancia no disponible
                  </Button>
                )}
              </Grid>
            </CardActions>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
}

export default CertificateCard;
