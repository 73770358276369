import React, { useEffect, useState } from "react";
import DetailsCard from "../../common/detailsCard";
import { useParams } from "react-router-dom";
import { history } from "../../../_helpers/history";
import { ASSOCIATE_CUIT, DOWNLOAD_CERTIFICATE_URI, NEW_TRANSACT_STEP_TWO } from "../../../routing/constants";
import { useDispatch, useSelector } from "react-redux";
import { clearDetails, tryGetActivitiesById, tryGetAssociateDetails } from "./associateDetailsSlice";
import {
  capitalizeTitle,
  getStatusDescriptionByCode,
  lowerCase,
  maskCUIT,
} from "../../../utils/utils";
import { Chip, Box } from "@mui/material";
import { formatDate } from "../../../services/formatService";
import AppLoading from "../../common/appLoading";
import DownloadIcon from "@mui/icons-material/Download";

export default function AssociateDetailsPage() {
  const { cuit } = useParams();
  const [buttonLabel, setButtonLabel] = useState("Volver");
  const data = useSelector((state) => state.associateDetails.associateDetails);
  const economicActivities = useSelector((state) => state.associateDetails.economicActivities);
  const isFetching = useSelector((state) => state.associateDetails.isFetching);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(clearDetails());
    dispatch(tryGetAssociateDetails(cuit));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!data || [5, 6, 7].includes(data?.status)) {
      setButtonLabel("Iniciar trámite de inscripción");
    } else if (data?.registrationReportUrl) {
      setButtonLabel("Descargar Constancia");
    }
    if (data) {
      dispatch(tryGetActivitiesById(data?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickButton = () => {
    if (!data || [5, 6, 7].includes(data?.status)) {
      history.navigate(NEW_TRANSACT_STEP_TWO);
    } else if (data?.activeReportUrls?.pending) {
      history.navigate(DOWNLOAD_CERTIFICATE_URI);
    } else if (data?.activeReportUrls?.current) {
      window.open(data?.activeReportUrls?.current, "_blank");
    }
  };

  const style = {
    fontSize: "2rem",
    fontFamily: "Open Sans",
    lineHeight: "2.4rem",
    margin: "0 0 2.4rem 0",
    padding: "0rem 2.4rem 0 5rem"
  }

  const bodyStyle = {
    padding: "2.2rem 0rem 0 0rem"
  }

  const semibold = {
    fontSize: "2rem",
    fontFamily: "Open Sans",
    lineHeight: "2.4rem",
    fontWeight: "600",
  }
  
  const cardStyle = {
    width:"80%"
  }

  // TODO: Elemento para separar secciones dentro del body de la card, ajustar CSS
  const BodyElement = (props) => {
    return <div style={{ borderBottom: "1px solid #CDCCCC"}}>
      {props.children}
    </div>
  }

  return (
    <Box
      sx={{
        width: "100%",
        pl: { xs: "2%", sm: "5%", md: "8rem", lg: "10rem" },
        mb:"2rem"
      }}
    >
      <DetailsCard
        title="Detalle de la Persona Asociada"
        buttonText={buttonLabel}
        buttonIcon={data?.registrationReportUrl && <DownloadIcon />}
        clickFunction={() => handleClickButton()}
        disabled={false}
        close={() => {
          history.navigate(ASSOCIATE_CUIT);
        }}
        style={cardStyle}
        bodyStyle={bodyStyle}
      >
        {isFetching ? (
          <AppLoading size={5} />
        ) : !data || data?.length === 0 ? (
          <p style={style}>Aún no es proveedor del Estado</p>
        ) : (
          Object.keys(data).length > 0 && (
            <>
              <p style={style}>
                <b style={semibold}>CUIT:</b> {maskCUIT(data?.cuit)}
              </p>
              <p style={style}>
                <b style={semibold}>Apellido y Nombre / Razón Social:</b>{" "}
                {data?.companyName}
              </p>
              <p style={style}>
                <b style={semibold}>Estado:</b>{" "}
                <Chip
                  label={getStatusDescriptionByCode(data?.status, "label", 2)}
                  sx={{
                    color: getStatusDescriptionByCode(
                      data?.status,
                      "fontColor",
                      2
                    ),
                    backgroundColor: getStatusDescriptionByCode(
                      data?.status,
                      "backgroundColor",
                      2
                    ),
                    fontWeight: "500",
                    fontSize: "16px",
                  }}
                />
              </p>
              <p style={style}>
                <b style={semibold}>Nombre de Fantasía:</b>{" "}
                {data?.fantasyName || " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Domicilio Real / Legal:</b>{" "}
                {data?.realAddress
                  ? `${capitalizeTitle(
                      data?.realAddress[0].name
                    )}${((data?.realAddress[0]?.province && ", " + capitalizeTitle(data?.realAddress[0]?.province.name)))} - ${capitalizeTitle(data?.realAddress[1])} `
                  : " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Domicilio Legal en Misiones:</b>{" "}
                {data?.legalAddress
                  ? `${capitalizeTitle(
                      data?.legalAddress[0].name
                    )} - ${capitalizeTitle(data?.legalAddress[1])} `
                  : " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Correo electrónico:</b>{" "}
                {data?.email || " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Correo electrónico alternativo:</b>{" "}
                {data?.secondaryEmail || " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Teléfono:</b> {data?.phoneNumber || " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Actividades Económicas:</b>{" "}
              </p>
                  {economicActivities !== null && economicActivities?.length !== 0 ?
                  <ul style={{margin:"0 0 2.4rem 5rem"}}>
                    {economicActivities?.map((activity) => (
                      <li key={activity.id}>{`${activity.code} - ${lowerCase(
                        activity.name
                      )}`}</li>
                    ))}
                    </ul>
                    :
                    <p style={{margin:"0 0 2.4rem 5rem"}}>No posee actividades económicas.</p>
              }
              <p style={style}>
                <b style={semibold}>Fecha de Inicio de Actividades:</b>{" "}
                {formatDate(data?.startDate) || " - "}
              </p>
              <p style={style}>
                <b style={semibold}>Fecha de Vencimiento:</b>{" "}
                {formatDate(data?.endDate) || " - "}
              </p>
            </>
          )
        )}
      </DetailsCard>
    </Box>
  );
}
