import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { history } from '../../_helpers/history';
import { useDispatch } from 'react-redux';
import { tryCloseModal } from '../modals/modalSlice';
import { ASSOCIATE_CUIT, DOWNLOAD_CERTIFICATE_URI, HOME, NEW_TRANSACT } from '../../routing/constants';

export default function Menus() {
  const dispatch = useDispatch();

  const handleAssociateMenu = () => {
    history.navigate(ASSOCIATE_CUIT);
    dispatch(tryCloseModal());
  }

    const locations = [
    {value: 0, path: HOME},
    {value: 1, path: NEW_TRANSACT},
    { value: 2, path: ASSOCIATE_CUIT },
    {value: 3, path: DOWNLOAD_CERTIFICATE_URI}
  ];

  const actualLocation = window.location.pathname;  
  const indexPage = locations.filter(element => element.path === actualLocation ? element : "");

  return (
    <Box sx={{ width: "90%" }}>
      <Box>
        <Tabs value={indexPage[0]?.value} aria-label="basic tabs example">
          <Tab
            variant="menuTab"
            sx={{ width: "90px" }}
            label="Inicio"
            onClick={() => history.navigate(HOME)}
          />
          <Tab
            variant="menuTab"
            sx={{ width: "155px" }}
            label="Iniciar trámite"
            onClick={() => history.navigate(NEW_TRANSACT)}
          />
          <Tab
            variant="menuTab"
            sx={{ width: "200px" }}
            label="Personas asociadas"
            onClick={handleAssociateMenu}
          />
          <Tab
            variant="menuTab"
            sx={{ width: "210px" }}
            label="Descargar constancia"
            onClick={() => history.navigate(DOWNLOAD_CERTIFICATE_URI)}
          />
        </Tabs>
      </Box>
    </Box>
  );
}
