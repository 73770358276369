import { Routes, Route, Navigate } from "react-router-dom";
import NewTransact from "../components/pages/newTransactPage";
import NewTransactStepTwo from "../components/pages/newTransactStepTwoPage";
import Home from "../components/pages/homePage";
import AssociatePage from "../components/pages/associatePage";
import NewTransactStepFourPage from "../components/pages/newTransactStepFourPage";
import NewTransactStepThree from "../components/pages/newTransactStepThreePage";
import NewTransactStepFive from "../components/pages/newTransactStepFivePage";
import { ROUTE_PATHS as PATHS } from "./constants";
import LoginPage from "../components/pages/loginPage/loginPage";
import ForgotPasswordPage from "../components/pages/passwordRecoveryPage/forgotPassword";
import PasswordChangePage from "../components/pages/passwordChangePage/passwordChangePage";
import ForgotPasswordPageStepTwo from "../components/pages/passwordRecoveryPage/forgotPasswordPageStepTwo";
import ForgotPasswordPageStepThree from "../components/pages/passwordRecoveryPage/forgotPasswordPageStepThree";
import RegisterPage from "../components/pages/registerPage/registerPage";
import { PrivateRoute } from "../components/common/privateRoute";
import RequirementsPage from "../components/pages/requirementsPage/requirementsPage";
import ProfilePage from "../components/pages/profilePage/profilePage";
import RegisterValidationPage from "../components/pages/registerPage/registerValidationPage";
import MunicipalAuthorizationPage from "../components/pages/municipalAuthorizationPage/municipalAuthorizationPage";
import MembersUploadPage from "../components/pages/membersUploadPage/membersUploadPage";
import RepresentativesPage from "../components/pages/representativesPage/representativesPage";
import AssociateDetailsPage from "../components/pages/associateDetailsPage/associateDetailsPage";
import RequirementsReviewPage from "../components/pages/requirementsReviewPage/requirementsReviewPage";
import DeleteAccountPage from "../components/pages/deleteAccount/deleteAccountPage";
import DownloadCertificate from "../components/pages/downloadCertificate/downloadCertificate";

const routes = (
        <Routes>
            <Route exact path={PATHS.HOME} element={<PrivateRoute> <Home/> </PrivateRoute> }/>
            <Route exact path={PATHS.LOGIN_URI} element={ <LoginPage/> }/>
            <Route exact path={PATHS.REGISTER_URI} element={ <RegisterPage/> } />
            <Route exact path={PATHS.REGISTER_VALIDATION_URI} element={<RegisterValidationPage/>}/>
            <Route exact path={PATHS.FORGOT_PASSWORD} element={<ForgotPasswordPage/>} />
            <Route exact path={PATHS.FORGOT_PASSWORD_STEP_TWO} element={<ForgotPasswordPageStepTwo />} />
            <Route exact path={PATHS.FORGOT_PASSWORD_STEP_THREE} element={<ForgotPasswordPageStepThree />} />
            <Route exact path={PATHS.CHANGE_PASSWORD} element={<PasswordChangePage />} />
            <Route exact path={`${PATHS.REQUIREMENTS_REVIEW_URI}/:checkCode`} element={<RequirementsReviewPage />} />
            <Route exact path={PATHS.DELETE_ACCOUNT_URI} element={<DeleteAccountPage />}/>
            <Route exact path={PATHS.ASSOCIATE_CUIT} element={ <PrivateRoute><AssociatePage/></PrivateRoute> } />
            <Route exact path={PATHS.NEW_TRANSACT} element= {<PrivateRoute> <NewTransact/> </PrivateRoute>}/>
            <Route exact path={PATHS.NEW_TRANSACT_STEP_FIVE} element={<PrivateRoute> <NewTransactStepFive/> </PrivateRoute>} />
            <Route exact path={PATHS.NEW_TRANSACT_STEP_FOUR} element= {<PrivateRoute> <NewTransactStepFourPage /></PrivateRoute>} />
            <Route exact path={PATHS.NEW_TRANSACT_STEP_TWO} element = {<PrivateRoute> <NewTransactStepTwo /></PrivateRoute>} />
            <Route exact path={PATHS.NEW_TRANSACT_STEP_THREE} element = {<PrivateRoute> <NewTransactStepThree /></PrivateRoute>} />
            <Route exact path={PATHS.REQUIREMENTS_URI} element = { <PrivateRoute> <RequirementsPage /></PrivateRoute>} />
            <Route exact path={PATHS.PROFILE_URI} element = { <PrivateRoute> <ProfilePage /></PrivateRoute> } />
            <Route exact path={PATHS.MUNICIPAL_AUTHORIZATION_URI} element = { <PrivateRoute> <MunicipalAuthorizationPage /></PrivateRoute> } />
            <Route exact path={PATHS.MEMBERS_UPLOAD_URI} element = { <PrivateRoute> <MembersUploadPage /> </PrivateRoute> }/>
            <Route exact path={PATHS.REPRESENTATIVES_URI} element={<PrivateRoute> <RepresentativesPage /> </PrivateRoute>} />
            <Route exact path={`${PATHS.ASSOCIATE_DETAILS_URI}/:cuit`} element={<PrivateRoute><AssociateDetailsPage/></PrivateRoute>} />
            <Route exact path={PATHS.DOWNLOAD_CERTIFICATE_URI} element={<PrivateRoute> <DownloadCertificate /> </PrivateRoute>} />
            <Route exact path="*" element={<Navigate to ="/" />} />
        </Routes>
    );

export default routes;