import * as React from "react";
import {
  Grid,
  CardActions,
  CardContent,
  Button,
  Typography,
  Card,
  Avatar,
} from "@mui/material";

export default function MediaCard(props) {
  const Icon = props.icon;
  const { handleButton, disabled = false, styles } = props;

  return (
    <div>
      <Card
        variant="actionCard"
        onClick={handleButton}
        sx={{
          minWidth: "20rem",
          maxWidth: "35rem",
          marginBottom: "3rem",
          height: "35rem",
          padding: "4rem 0 0 0",
          backgroundColor: "rgba(255,255,255,1)",
          transition: "all 0.3s ease-in-out",
          "&:hover": {
            cursor: "pointer",
            borderBottom: "6px solid #086CAD",
            transform: "translateY(-6px)",
            boxShadow: "2px 4px 16px rgba(0, 0, 0, 0.2)",
          },
          ...styles,
        }}
      >
        <Grid
          sx={{
            paddingTop: "1rem",
            maxWidth: "38rem",
            width: "100%",
            minWidth: "10dvw",
            textAlign: "-webkit-center",
          }}
        >
          <Avatar
            sx={{
              height: "8rem",
              width: "8rem",
              p: "1.2rem",
              top: "0rem",
              background: "transparent",
              "&:hover": { cursor: "pointer" },
              "& img": {
                width: "100%",
                height: "100%",
                objectFit: "contain",
                objectPosition: "center",
              },
            }}
            onClick={handleButton}
          >
            <img src={Icon} alt="Card Icon" />
          </Avatar>
        </Grid>
        <CardContent
          sx={{ height: "19rem", padding: "2rem", textAlign: "center" }}
        >
          <Typography
            gutterBottom
            variant="actionCardTitle"
            component="div"
            justifyContent="center"
          >
            {props.title}
          </Typography>
          <br></br>
          <Typography
            variant="actionCardDescription"
            justifyContent="center"
            height="35rem"
          >
            {props.description}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}
