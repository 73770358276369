import React, { useEffect, useState } from "react";
import CertificateCard from "./certificateCard";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import AppLoading from "../../common/appLoading";
import { fetchManagedPersons } from "../../../features/newTransact/newTransactSlice";
import { getProviderDetails } from "../../../services/userService";
import SearchInput from "../../common/searchInput";

function DownloadCertificate() {
  const users = useSelector((state) => state?.newTransact?.persons);
  const isFetchingManagedPersons = useSelector(
    (state) => state?.newTransact?.isFetching
  );
  const dispatch = useDispatch();

  const [updatedUsers, setUpdatedUsers] = useState([]);
    const [loadingDetails, setLoadingDetails] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    dispatch(fetchManagedPersons());
  }, [dispatch]);

  useEffect(() => {
    const fetchDetailsForUsers = async () => {
      if (users?.length > 0) {
        setLoadingDetails(true);
        try {
          const updated = await Promise.all(
            users.map(async (user) => {
              try {
                const details = await getProviderDetails(user.cuit);
                return { ...user, providerDetails: details };
              } catch (err) {
                console.error(
                  "Error fetching details for user:",
                  user.cuit,
                  err
                );
                return { ...user, providerDetails: null };
              }
            })
          );
          const sortedUsers = [...updated].sort((a, b) => {
            const getStatusPriority = (status) => {
              if (status === 4) return 1;
              if (status === 2 || status === 3) return 2; 
              return 3; 
            };

            const priorityA = getStatusPriority(a.providerDetails?.status || 0);
            const priorityB = getStatusPriority(b.providerDetails?.status || 0);

            return priorityA - priorityB; 
          });
          setUpdatedUsers(sortedUsers);
        } catch (error) {
          console.error("Error fetching details for users:", error);
        } finally {
          setLoadingDetails(false);
        }
      } else {
        console.info("No users to fetch details for.");
      }
    };

    fetchDetailsForUsers();
  }, [users]);
    
    const filteredUsers = updatedUsers.filter((user) => {
      const searchQuery = searchTerm.toLowerCase();
      return (
        user.name.toLowerCase().includes(searchQuery) ||
        user.cuit.includes(searchQuery)
      );
    });

  return (
    <Grid container direction="column" sx={{ width: "80%" }}>
      <Grid item>
        <Typography variant="title">Descarga de constancia</Typography>
        <br />
        <Typography variant="textParagraph">
          Obtenga la constancia definitiva del último trámite aceptado o la
          constancia provisoria si tiene un trámite iniciado (que no haya sido
          denegado, desistido o aceptado).
        </Typography>
      </Grid>
      <Grid item sx={{ m: "6rem 0 3rem 0" }}>
        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} />{" "}
      </Grid>
      <Grid item>
        {filteredUsers.length > 0 && !loadingDetails ? (
          filteredUsers.map((item, index) => (
            <CertificateCard
              key={index}
              buttonLabel="Constancia definitiva"
              data={item}
              disabled={!item?.providerDetails?.activeReportUrls}
            />
          ))
        ) : loadingDetails || isFetchingManagedPersons ? (
          <AppLoading size={5} />
        ) : (
          <Container align="center" sx={{ pt: "5rem" }}>
            <Typography
              variant="title"
              sx={{ fontSize: "1.6rem", fontWeight: 400 }}
            >
              No posee personas asociadas.
            </Typography>
          </Container>
        )}
      </Grid>
    </Grid>
  );
}

export default DownloadCertificate;
